export const updateColumnCookies = (columns) => {
  localStorage.setItem("NPS_COLUMNS", JSON.stringify(columns));
};
export const getColumnCookies = () => JSON.parse(localStorage.getItem("NPS_COLUMNS"));

export const updateFilterCookies = (filters) => {
  localStorage.setItem("NPS_FILTERS", JSON.stringify(filters));
};

export const getFilterCookies = () => {
  return JSON.parse(localStorage.getItem("NPS_FILTERS"));
};

export const clearFilterCookies = () => {
  localStorage.removeItem("NPS_FILTERS");
};

export const setColumnFilterCookies = (filters, table) => {
  localStorage.setItem(`NPS_COLUMN_FILTERS_${table}`, JSON.stringify({ ...getColumnFilterCookies(), ...filters }));
};
export const getColumnFilterCookies = (table) => {
  return JSON.parse(localStorage.getItem(`NPS_COLUMN_FILTERS_${table}`));
};
export const resetColumnFilters = (table) => {
  localStorage.removeItem(`NPS_COLUMN_FILTERS_${table}`);
};

//for dev
export const clearLocalStorage = () => {
  localStorage.clear();
};
