import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

function NavBar() {
  const { filters } = useSelector((state) => state.app);
  const hasSFDCAccess = useSelector((state) => state.app.hasSFDCAccess);
  return (
    <nav className="bg-[#ebebeb] sticky top-14 z-10">
      <ul className="flex flex-row gap-4 ml-5 list-none nav-list">
        <li className="p-1 text-sm cursor-pointer hover:font-bold hover:text-[#57b2fc]">
          <NavLink to="/" className="text-black no-underline">
            NPS Evaluation
          </NavLink>
        </li>
        {hasSFDCAccess ? (
          <li className="p-1 text-sm cursor-pointer hover:font-bold hover:text-[#57b2fc]">
            <NavLink to="/salesforce" className="text-black no-underline">
              SalesForce
            </NavLink>
          </li>
        ) : null}{" "}
        <li className="p-1 text-sm cursor-pointer hover:font-bold hover:text-[#57b2fc]">
          <NavLink to="/guidelines" className="text-black no-underline">
            Guidelines
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default NavBar;
