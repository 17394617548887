import { AutoComplete, Button, Select } from "antd";
import { getMonthOptions, getYearOptions } from "../../utils/monthUtils";
import { memo, useContext, useState } from "react";
import { AppContext } from "../../App";
import { getColumnFilterCookies, getFilterCookies, resetColumnFilters } from "../../utils/cookies";
import { refreshTaggingFields } from "../Table";
import { useDispatch, useSelector } from "react-redux";
import { CheckOutlined, DeleteOutlined } from "@ant-design/icons";
import { getData, resetTaggingForm, updateData } from "../../redux/actions/appActions";
import useTagging from "../../hooks/useTagging";
import { updateUserSelection } from "../../redux/actions/userSelectionActions";

function DHeader({ tableRef, npsTableRef }) {
  const yearOptions = getYearOptions();
  const monthOptions = getMonthOptions();
  const { GMIDYearLocal, rowSelectForm, setGMIDMonthLocal, setGMIDYearLocal, messageApi } = useContext(AppContext);
  const dispatch = useDispatch();
  const { duplicateData, pageSize, currentPage } = useSelector((state) => state.app);
  const { gmidMonth, salesYear, gmidYear } = useSelector((state) => state.selections);
  const { submitTagging } = useTagging();
  const [lastTimeout, setLastTimeout] = useState(null);

  const applyFilters = async (year, month, sales) => {
    messageApi.open({
      type: "loading",
      content: "Applying filters, please wait",
      duration: 60,
      key: "filters",
    });
    const cachedFilters = getFilterCookies();
    dispatch(resetTaggingForm(rowSelectForm));
    await dispatch(
      getData({
        ...cachedFilters,
        gmidyear: year,
        gmidmonth: month,
        salesyear: sales,
      })
    )
      .then((res) => {
        messageApi.open({
          key: "filters",
          type: "success",
          duration: 5,
          content: "Filters applied",
        });
        rowSelectForm.resetFields();
      })
      .catch((err) => {
        messageApi.open({
          key: "filters",
          type: "error",
          duration: 5,
          content: err.message,
        });
      });
  };
  const onChangeHandler = (year = gmidYear, month = gmidMonth, sales = salesYear) => {
    if (lastTimeout) clearTimeout(lastTimeout);
    let timeOut = setTimeout(() => applyFilters(year, month, sales), 700);
    setLastTimeout(timeOut);
  };

  return (
    <div className="flex flex-row justify-between items-center mb-3">
      <span className="text-red-500 font-normal text-3xl ml-5">NPS Evaluation</span>
      <div className="flex gap-2 items-center">
        <span>Material Created Date</span>
        <AutoComplete
          style={{ width: 100 }}
          placeholder="Year"
          options={yearOptions}
          value={GMIDYearLocal}
          onChange={(year) => {
            setGMIDYearLocal(year);
            dispatch(updateUserSelection({ gmidYear: year }));
            onChangeHandler(year);
          }}
        />
        <Select
          style={{ width: 100 }}
          placeholder="Month"
          value={gmidMonth}
          options={monthOptions}
          onChange={(month) => {
            dispatch(updateUserSelection({ gmidMonth: month }));
            setGMIDMonthLocal(month);
            onChangeHandler(gmidYear, month);
          }}
        />
        <div className="flex gap-2 items-center">
          <span>Sales Year</span>
          <Select
            style={{ width: 100 }}
            placeholder="Sales Year"
            options={yearOptions.slice(0, 2)}
            value={salesYear}
            onChange={(year) => {
              dispatch(updateUserSelection({ salesYear: year }));
              onChangeHandler(gmidYear, gmidMonth, year);
            }}
          />
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <Button
          onClick={() => {
            resetColumnFilters(1);
            refreshTaggingFields(rowSelectForm, duplicateData, pageSize, currentPage,1);
            dispatch(updateData(duplicateData));
            if (tableRef) tableRef.reset(duplicateData);
            if (npsTableRef) npsTableRef.reset(duplicateData);
          }}
          type="default"
          icon={<DeleteOutlined />}
          disabled={!getColumnFilterCookies(1)}
        >
          Clear Filters
        </Button>
        <Button
          onClick={() => {
            submitTagging();
          }}
          type="default"
          icon={<CheckOutlined />}
        >
          Save
        </Button>
      </div>
    </div>
  );
}

export default memo(DHeader);
