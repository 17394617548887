export const actionTypes = {
  UPDATE_COLUMNS: "UPDATE_COLUMNS",
  UPDATE_DATA: "UPDATE_DATA",
  UPDATE_APPLICATION_DATA: "UPDATE_APPLICATION_DATA",
  UPDATE_FILTERS: "UPDATE_FILTERS",
  UPDATE_DUPLICATE_DATA: "UPDATE_DUPLICATE_DATA",
  UPDATE_SALESFORCE_DATA: "UPDATE_SALESFORCE_DATA",
  UPDATE_DUPLICATE_SALESFORCE_DATA: "UPDATE_DUPLICATE_SALESFORCE_DATA",
  HAS_SFDC_ACCESS: `HAS_SFDC_ACCESS`,
  UPDATE_FILTER_CONFIGURATION: `UPDATE_FILTER_CONFIGURATION`,
  APP_LOADING: `APP_LOADING`,
  HAS_APP_ACCESS: `HAS_APP_ACCESS`,
  SET_APP_ERROR: `SET_APP_ERROR`,
  SET_UPDATES_ONE: `SET_UPDATES_ONE`,
  SET_UPDATES_TWO: `SET_UPDATES_TWO`,
  SET_SAP_ROW: `SET_SAP_ROW`,
  SET_PROD_CUST_ROW: `SET_PROD_CUST_ROW`,
  SET_SALESFORCE_ROW: `SET_SALESFORCE_ROW`,
};

export const userSelectionType = {
  UPDATE_USER_SELECTION: "UPDATE_USER_SELECTION",
};
