export const getYearOptions = () => {
  let array = [];
  for (let i = 0; i < 5; i++) {
    let year = new Date().getFullYear() - i;
    array.push({
      value: year,
      label: year,
    });
  }
  return array;
};

export const getMonthOptions = () => [
  { value: "001", label: "January" },
  { value: "002", label: "February" },
  { value: "003", label: "March" },
  { value: "004", label: "April" },
  { value: "005", label: "May" },
  { value: "006", label: "June" },
  { value: "007", label: "July" },
  { value: "008", label: "August" },
  { value: "009", label: "September" },
  { value: "010", label: "October" },
  { value: "011", label: "November" },
  { value: "012", label: "December" },
];

export const monthDict = ["001", "002", "003", "004", "005", "006", "007", "008", "009", "010", "011", "012"];

export const getDefaultGMidMonthYear = () => {
  const date = new Date();
  let currentMonth = date.getMonth();
  let month = currentMonth === 0 ? 11 : currentMonth - 1;
  let year = currentMonth === 0 ? date.getFullYear() - 1 : date.getFullYear();
  return {
    year: year,
    month: monthDict[month],
  };
};

export const getNpsYearOptions = (salesYear) => {
  let array = [];
  for (let i = 0; i < 5; i++) {
    let year = salesYear - i;
    array.push({
      value: year,
      label: year,
    });
  }
  return array;
}