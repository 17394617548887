import { actionTypes } from "../action.types";

const initialState = {
  columns: null,
  data: null,
  filters: null,
  applicationData: null,
  duplicateData: null,
  salesforceData: null,
  duplicateSalesforceData: null,
  hasSFDCAccess: false,
  filterConfiguration: null,
  isAppLoading: false,
  hasAccessToApp: true,
  appError: false,
  updatesOne: {},
  updatesTwo: {},
};
export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_COLUMNS:
      return { ...state, columns: action.payload };
    case actionTypes.UPDATE_DATA:
      return {
        ...state,
        data: action.payload,
      };
    case actionTypes.UPDATE_DUPLICATE_DATA:
      return {
        ...state,
        duplicateData: action.payload,
      };

    case actionTypes.UPDATE_FILTERS:
      return { ...state, filters: action.payload };
    case actionTypes.UPDATE_SALESFORCE_DATA:
      return { ...state, salesforceData: action.payload };
    case actionTypes.UPDATE_DUPLICATE_SALESFORCE_DATA:
      return {
        ...state,
        duplicateSalesforceData: action.payload,
      };
    case actionTypes.HAS_SFDC_ACCESS:
      return {
        ...state,
        hasSFDCAccess: action.payload,
      };
    case actionTypes.UPDATE_FILTER_CONFIGURATION:
      return {
        ...state,
        filterConfiguration: action.payload,
      };
    case actionTypes.APP_LOADING:
      return {
        ...state,
        isAppLoading: action.payload,
      };
    case actionTypes.HAS_APP_ACCESS:
      return {
        ...state,
        hasAccessToApp: action.payload,
      };
    case actionTypes.SET_APP_ERROR:
      return {
        ...state,
        appError: action.payload,
      };
    case actionTypes.UPDATE_APPLICATION_DATA:
      return {
        ...state,
        applicationData: action.payload,
      };
    case actionTypes.SET_UPDATES_ONE:
      return { ...state, updatesOne: action.payload };
    case actionTypes.SET_UPDATES_TWO:
      return { ...state, updatesTwo: action.payload };
    case actionTypes.SET_SAP_ROW:
      state.data[action.payload.index] = { ...state.data[action.payload.index], ...action.payload.row };
      return { ...state };
    case actionTypes.SET_PROD_CUST_ROW:
      state.applicationData[action.payload.index] = { ...state.applicationData[action.payload.index], ...action.payload.row };
      return { ...state };
    case actionTypes.SET_SALESFORCE_ROW:
      state.salesforceData[action.payload.index] = { ...state.salesforceData[action.payload.index], ...action.payload.row };
      return { ...state };
    default:
      return state;
  }
};
