import { Button, Form } from "antd";
import { refreshTaggingFields } from "./Table";
import { useDispatch, useSelector } from "react-redux";
import { columnCategories } from "../utils/columns";
import "react-table-filter/lib/styles.css";
import React, { useContext } from "react";
import { updateApplicationData, setUpdatesOne, setUpdatesTwo } from "../redux/actions/appActions";
import Loading from "./Loading";
import { DeleteOutlined } from "@ant-design/icons";
import { AppContext, TableFilterContext } from "../App";
import { getColumnFilterCookies, resetColumnFilters } from "../utils/cookies";
import FinalTable from "./FinalTable";
import DHeader from "./Dashboard/DHeader";
import { AppError, ErrorPage } from "../components/ErrorPage";

function DashboardBody() {
  const { columns, data, applicationData, duplicateData, updatesOne, updatesTwo } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const { isAppLoading, appError } = useSelector((state) => state.app);
  const { tableRef, setTableRef, tableRefTwo, setTableRefTwo, npsTableRef, setNPSTableRef, npsTableRefTwo, setNPSTableRefTwo } =
    useContext(TableFilterContext);
  const {
    rowSelectForm,
    rowsToBeUpdated,
    setRowsToBeUpdated,
    rowsToBeUpdatedForTableTwo,
    setRowsToBeUpdatedForTableTwo,
    pageSize,
    currentPage,
    setCurrentPage,
    currentPageTwo,
    setCurrentPageTwo,
    setFilterDrawerOpen,
  } = useContext(AppContext);

  if (appError) {
    return <AppError />;
  }
  if (isAppLoading || !data) {
    return <Loading />;
  }
  return (
    <div className="w-full px-10 mb-20" style={{ marginTop: 20 }}>
      <DHeader npsTableRef={npsTableRef} tableRef={tableRef} />
      <Form form={rowSelectForm}>
        {duplicateData && duplicateData.length ? (
          <>
            <div className="w-full bg-white items-center shadow-xl">
              <div className="flex flex-row w-full bg-white items-center shadow-xl px-5 pt-2">
                <h2 className="text-[1.1rem] w-[54vw]">{columnCategories.PRODUCT_DATA}</h2>
                <h2 className="text-[1.1rem] ">{columnCategories.NPS_TAGGING_DATA}</h2>
              </div>
              <FinalTable
                form={rowSelectForm}
                data={data}
                columns={columns[columnCategories.PRODUCT_DATA]}
                npsColumns={columns[columnCategories.NPS_TAGGING_DATA]}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                currentPage={currentPage}
                setRowsToBeUpdated={setRowsToBeUpdated}
                rowsToBeUpdated={rowsToBeUpdated}
                updates={updatesOne}
                setUpdates={setUpdatesOne}
                table={1} // this is table id, set to one for product data table and set to 2 for prod/customer data
                setTableRef={setTableRef}
                tableRef={tableRef}
                npsTableRef={npsTableRef}
                setNpsTableRef={setNPSTableRef}
              />
            </div>
            <div className="mt-14 w-full items-end flex flex-col ">
              <Button
                onClick={() => {
                  resetColumnFilters(2);
                  refreshTaggingFields(rowSelectForm, duplicateData, pageSize, currentPage,2);
                  dispatch(updateApplicationData(duplicateData));
                  if (tableRefTwo) tableRefTwo.reset(duplicateData);
                  if (npsTableRef) npsTableRefTwo.reset(duplicateData);
                }}
                type="default"
                icon={<DeleteOutlined />}
                disabled={!getColumnFilterCookies(2)}
              >
                Clear Filters
              </Button>
            </div>
            <div className="w-full bg-white items-center shadow-xl">
              <div className="flex flex-row w-full bg-white items-center mt-3 shadow-xl px-5 pt-2">
                <h2 className="text-[1.1rem] w-[55vw]">{columnCategories.PRODUCT_CUSTOMER_DATA}</h2>
                <h2 className="text-[1.1rem] w-[36vw] flex flex-row justify-between mb-2">{columnCategories.APPLICATION_TAGGING}</h2>
              </div>
              <FinalTable
                form={rowSelectForm}
                data={applicationData}
                columns={columns[columnCategories.PRODUCT_CUSTOMER_DATA]}
                npsColumns={columns[columnCategories.APPLICATION_TAGGING]}
                setCurrentPage={setCurrentPageTwo}
                pageSize={pageSize}
                currentPage={currentPageTwo}
                setRowsToBeUpdated={setRowsToBeUpdatedForTableTwo}
                rowsToBeUpdated={rowsToBeUpdatedForTableTwo}
                updates={updatesTwo}
                setUpdates={setUpdatesTwo}
                table={2} // this is table id, set to one for product data table and set to 2 for prod/customer data
                setTableRef={setTableRefTwo}
                tableRef={tableRefTwo}
                npsTableRef={npsTableRefTwo}
                setNpsTableRef={setNPSTableRefTwo}
              />
            </div>
          </>
        ) : (
          <ErrorPage setFilterDrawerOpen={setFilterDrawerOpen} />
        )}
      </Form>
    </div>
  );
}

export default DashboardBody;
