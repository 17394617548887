import { useIsAuthenticated } from "@azure/msal-react";
import Login from "../components/Login";
import Loading from "../components/Loading";
import { useSelector } from "react-redux";
import { Result } from "antd";

function ProtectedRoute({ children }) {
  const isAuthenticated = useIsAuthenticated();
  const { authInProgress } = useSelector((state) => state.auth);
  const { isAppLoading } = useSelector((state) => state.app);
  const { roles } = useSelector((state) => state.auth);
  if (!roles || (roles && roles.length === 0)) {
    return <Result status="403" title="403" subTitle="Sorry, you do not have access to this application." />;
  }
  if (authInProgress && isAppLoading) return <Loading />;
  if (isAuthenticated) return children;
  return <Login />;
}

export default ProtectedRoute;
