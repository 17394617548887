import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { DataProvider } from "./redux/store";
import { PublicClientApplication } from "@azure/msal-browser";
import { authConfig } from "./config/authConfig";
import { MsalProvider } from "@azure/msal-react";

const msalInstance = new PublicClientApplication(authConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <MsalProvider instance={msalInstance}>
    <DataProvider>
      <App />
    </DataProvider>
  </MsalProvider>,
);
