import { axiosClient } from "../../config/axiosClient";
import { actionTypes } from "../action.types";
import { store } from "../store";

export const updateColumns = (columns) => ({
  type: actionTypes.UPDATE_COLUMNS,
  payload: columns,
});

export const updateData = (data) => ({
  type: actionTypes.UPDATE_DATA,
  payload: data,
});
export const updateApplicationData = (data) => ({
  type: actionTypes.UPDATE_APPLICATION_DATA,
  payload: data,
});
export const updateDuplicateData = (data) => ({
  type: actionTypes.UPDATE_DUPLICATE_DATA,
  payload: data,
});

export const updateFilters = (data) => ({
  type: actionTypes.UPDATE_FILTERS,
  payload: data,
});
export const updateSalesForceData = (data) => ({
  type: actionTypes.UPDATE_SALESFORCE_DATA,
  payload: data,
});
export const updateDuplicateSalesForceData = (data) => ({
  type: actionTypes.UPDATE_DUPLICATE_SALESFORCE_DATA,
  payload: data,
});

export const updateHasSFDCAccess = (access = false) => ({
  type: actionTypes.HAS_SFDC_ACCESS,
  payload: access,
});
export const updateFilterConfiguration = (config) => ({
  type: actionTypes.UPDATE_FILTER_CONFIGURATION,
  payload: config,
});
export const updateAppLoading = (flag) => ({
  type: actionTypes.APP_LOADING,
  payload: flag,
});

export const updateAppAccess = (flag) => ({
  type: actionTypes.HAS_APP_ACCESS,
  payload: flag,
});
export const setAppError = (error) => ({
  type: actionTypes.SET_APP_ERROR,
  payload: error,
});

export const setUpdatesOne = (updates) => ({
  type: actionTypes.SET_UPDATES_ONE,
  payload: updates,
});
export const setUpdatesTwo = (updates) => ({
  type: actionTypes.SET_UPDATES_TWO,
  payload: updates,
});
export const updateSAPRow = (row, index) => ({
  type: actionTypes.SET_SAP_ROW,
  payload: { row, index },
});
export const updateProdCustRow = (row, index) => ({
  type: actionTypes.SET_PROD_CUST_ROW,
  payload: { row, index },
});

export const updateSalesforceRow = (row, index) => ({
  type: actionTypes.SET_SALESFORCE_ROW,
  payload: { row, index },
});
export const getFilters = () => async (dispatch) => {
  dispatch(updateAppLoading(true));
  axiosClient
    .get("/getFilters")
    .then((res) => {
      let keys = Object.keys(res).sort();
      if (res && keys.length === 0) dispatch(updateAppAccess(false));
      if (res["ELECTRONICS & INDUSTRIAL"] && res["ELECTRONICS & INDUSTRIAL"]["INDUSTRIAL SOLUTIONS"]) {
        let idx = res["ELECTRONICS & INDUSTRIAL"]["INDUSTRIAL SOLUTIONS"].findIndex((el) => el.value === "LIVEO");
        if (idx > -1) {
          dispatch(updateHasSFDCAccess(true));
        }
      }
      dispatch(updateAppLoading(false));
      dispatch(updateFilters(res));
    })
    .catch((err) => {
      console.log(err);
      dispatch(updateAppLoading(false));
    });
};

export const resetTaggingForm = (form) => (dispatch) => {
  dispatch(updateData([]));
  dispatch(updateDuplicateData([]));
  form.resetFields();
};

export const getData = (data) => async (dispatch) => {
  dispatch(updateData(null));
  dispatch(updateDuplicateData(null));
  dispatch(updateAppLoading(true));
  dispatch(setAppError(null));

  return new Promise(async (resolve, reject) => {
    if (!store.getState().app.hasAccessToApp) {
      reject("Something went wrong, Please try again");
      return null;
    } else {
      await axiosClient
        .post(`/getdata`, data)
        .then((res) => {
          dispatch(updateData(res));
          dispatch(updateApplicationData(JSON.parse(JSON.stringify(res))));
          dispatch(updateDuplicateData(JSON.parse(JSON.stringify(res))));
          resolve("Filters Applied");
          dispatch(updateAppLoading(false));
        })
        .catch((err) => {
          console.log(err);
          dispatch(setAppError(err));
          dispatch(updateAppLoading(false));
          reject("Something went wrong, Please try again");
        });
    }
  });
};

export const getSalesForceData =
  (filters = {}) =>
  async (dispatch) => {
    dispatch(updateSalesForceData(null));
    dispatch(updateDuplicateSalesForceData(null));
    dispatch(setAppError(null));

    return new Promise(async (resolve, reject) => {
      await axiosClient
        .post(`/getsfdc`, filters)
        .then((res) => {
          dispatch(updateData(res));
          dispatch(updateDuplicateData(res));
          resolve("Filters Applied");
        })
        .catch((err) => {
          console.log(err);
          dispatch(setAppError(err));
          reject("Something went wrong, Please try again");
        });
    });
  };

export const updateNPSTagging = async (data) => {
  return new Promise(async (resolve, reject) => {
    await axiosClient
      .post("/updateNPSTagging", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
