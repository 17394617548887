import moment from "moment-timezone";
import { memo, useMemo } from "react";

function TimeStampRenderer({ timestamp }) {
  const getLocalizedTime = useMemo(() => {
    if (timestamp) {
      const format = `DD/MM/YYYY, hh:mm:ss A`;
      return moment.utc(timestamp).local().format(format);
    }
    return null;
  }, [timestamp]);

  return <>{getLocalizedTime}</>;
}

export default memo(TimeStampRenderer);
