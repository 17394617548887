import { Tooltip } from "antd";
import { useDispatch } from "react-redux";
import TableFilter from "react-table-filter";
import "react-table-filter/lib/styles.css";
import { updateData, updateApplicationData, updateSalesForceData } from "../redux/actions/appActions";
import { memo, useCallback, useEffect } from "react";
import { getColumnFilterCookies, setColumnFilterCookies } from "../utils/cookies";
import { usePage } from "../hooks/usePage";

export const refreshTaggingFields = (form, data, pageSize, currentPage = 1, table) => {
  const formUpdates = form.getFieldsValue();
  for (let i = (currentPage - 1) * pageSize; i < Math.min(pageSize, data.length); i++) {
    let checkbox = document.getElementById(`check-row-${table}-${i}`);
    if (checkbox) checkbox.checked = false;
    formUpdates[`QualifyAsNPS-${table}-${i}`] = data[i].QualifyAsNPS;
    formUpdates[`NPSPercentageNew-${table}-${i}`] = data[i].NPSPercentageNew;
    formUpdates[`NPSCriteria-${table}-${i}`] = data[i].NPSCriteria;
    formUpdates[`NPSPercentageRenew-${table}-${i}`] = data[i].NPSPercentageRenew;
    formUpdates[`SustnAdvgedSales-${table}-${i}`] = data[i].SustnAdvgedSales;
    formUpdates[`SustnAdvgedSalesPercentage-${table}-${i}`] = data[i].SustnAdvgedSalesPercentage;
    formUpdates[`NPSYear-${table}-${i}`] = data[i].NPSYear;
    formUpdates[`Project-${table}-${i}`] = data[i].Project;
    formUpdates[`Commentary-${table}-${i}`] = data[i].Commentary;
  }
  form.setFieldsValue(formUpdates);
};

function Table({
  columns,
  data,
  form,
  pageSize,
  currentPage,
  setRowsToBeUpdated,
  table,
  rowsToBeUpdated,
  setCurrentPage,
  setTableRef,
  npsTableRef,
}) {
  const page = usePage();
  const dispatch = useDispatch();
  const selectRowHandler = useCallback(
    (rowIndex) => {
      setRowsToBeUpdated((prevState) => {
        let set = new Set(prevState);
        const checkbox = document.getElementById(`check-row-${table}-${rowIndex}`);
        if (!set.has(rowIndex)) {
          set.add(rowIndex);
          checkbox.checked = true;
          return Array.from(set);
        } else {
          set.delete(rowIndex);
          checkbox.checked = false;
          return Array.from(set);
        }
      });
    },
    [setRowsToBeUpdated, table]
  );

  useEffect(() => {
    const checkBoxes = document.getElementsByClassName("row-select-check");
    for (let i = 0; i < checkBoxes.length; i++) {
      if (checkBoxes[i]) checkBoxes[i].checked = false;
    }
    setTimeout(() => {
      rowsToBeUpdated.forEach((row) => {
        const checkbox = document.getElementById(`check-row-${table}-${row}`);
        if (checkbox) checkbox.checked = true;
      });
    }, 100);
  }, [currentPage]);

  return data ? (
    <table>
      <thead>
        <TableFilter
          rows={data}
          initialFilters={getColumnFilterCookies(table)}
          onFilterUpdate={(newData, filterConfiguration) => {
            setColumnFilterCookies(filterConfiguration, table);
            if (npsTableRef) npsTableRef?.reset(newData);
            if (page === "SAP") {
              if (table === 1) dispatch(updateData(newData));
              else dispatch(updateApplicationData(newData));
            } else {
              dispatch(updateSalesForceData(newData));
            }
            setCurrentPage(1);
            refreshTaggingFields(form, newData, pageSize, currentPage, table);
          }}
          ref={(node) => setTableRef(node)}
        >
          {columns
            .filter((column) => column.visibility)
            .map((column) =>
              column.visibility ? (
                <th
                  key={column.dataIndex}
                  filterkey={column.dataIndex === "select" ? null : column.dataIndex}
                  alignleft="true"
                  style={{
                    width: column.width || 150,
                    minWidth: column.width || 150,
                  }}
                >
                  {column.title}
                </th>
              ) : null
            )}
        </TableFilter>
      </thead>
      <tbody>
        {data &&
          data.slice((currentPage - 1) * pageSize, currentPage * pageSize).map((row, rindex) => (
            <tr key={rindex} className={`product-data-${rindex}`}>
              {columns &&
                columns.map((column, index) =>
                  column.visibility ? (
                    column.dataIndex === "select" ? (
                      <td key={`${index - column.dataIndex}`} className="row-select">
                        <input
                          type="checkbox"
                          className={`row-select-check check-row-${table} w-4 h-4 ml-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600`}
                          style={{
                            marginTop: 10,
                            marginBottom: -20,
                          }}
                          defaultChecked={false}
                          id={`check-row-${table}-${rindex + (currentPage - 1) * pageSize}`}
                          onChange={() => {
                            selectRowHandler(rindex + (currentPage - 1) * pageSize);
                          }}
                        />
                      </td>
                    ) : (
                      <td key={index}>
                        {!row[column.dataIndex] || row[column.dataIndex]?.toString().length < 20 ? (
                          row[column.dataIndex]?.toString().trim()
                        ) : (
                          <Tooltip title={row[column.dataIndex]}>
                            {row[column.dataIndex].toString().trim().substring(0, 20) + "..."}
                          </Tooltip>
                        )}
                      </td>
                    )
                  ) : null
                )}
            </tr>
          ))}
      </tbody>
    </table>
  ) : null;
}

export default memo(Table);
