import { createContext, useEffect, useState } from "react";
import Routing from "./routes/Routing";
import { useDispatch, useSelector } from "react-redux";

import { getFilters } from "./redux/actions/appActions";
import { Form, message } from "antd";
import { clearLocalStorage, getFilterCookies } from "./utils/cookies";
import useAuth from "./hooks/useAuth";

export const AppContext = createContext();
export const TableFilterContext = createContext();

function App() {
  // eslint-disable-next-line no-unused-vars
  const auth = useAuth();
  const { gmidMonth, gmidYear } = useSelector((state) => state.selections);
  const [GMIDMonthLocal, setGMIDMonthLocal] = useState(gmidMonth);
  const [GMIDYearLocal, setGMIDYearLocal] = useState(gmidYear);
  const [rowsToBeUpdated, setRowsToBeUpdated] = useState([]);
  const [rowsToBeUpdatedForTableTwo, setRowsToBeUpdatedForTableTwo] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const { isAuthenticated, token } = useSelector((state) => state.auth);
  const [rowSelectForm] = Form.useForm();
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageTwo, setCurrentPageTwo] = useState(1);
  const [toolbarValues, settoolbarValues] = useState(null);
  const [toolbarForm] = Form.useForm();
  const [isFilterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [salesforceRowsTobeUpdated, setSalesforceRowsTobeUpdated] = useState([]);
  const [updatesSfdc, setUpdatesSfdc] = useState();
  const [filterFormDisabled, setfilterFormDisabled] = useState(getFilterCookies()?.dataSource === "SFDC" || false);
  const [tableRef, setTableRef] = useState(null);
  const [tableRefTwo, setTableRefTwo] = useState(null);
  const [npsTableRef, setNPSTableRef] = useState(null);
  const [npsTableRefTwo, setNPSTableRefTwo] = useState(null);

  const tableFilterRefs = {
    tableRef,
    setTableRef,
    tableRefTwo,
    setTableRefTwo,
    npsTableRef,
    setNPSTableRef,
    npsTableRefTwo,
    setNPSTableRefTwo,
  };
  const appContextValues = {
    messageApi,
    rowSelectForm,
    GMIDMonthLocal,
    setGMIDMonthLocal,
    GMIDYearLocal,
    setGMIDYearLocal,
    rowsToBeUpdated,
    setRowsToBeUpdated,
    rowsToBeUpdatedForTableTwo,
    setRowsToBeUpdatedForTableTwo,
    toolbarValues,
    settoolbarValues,
    pageSize,
    setPageSize,
    currentPage,
    setCurrentPage,
    toolbarForm,
    isFilterDrawerOpen,
    setFilterDrawerOpen,
    salesforceRowsTobeUpdated,
    setSalesforceRowsTobeUpdated,
    updatesSfdc,
    setUpdatesSfdc,
    filterFormDisabled,
    setfilterFormDisabled,
    currentPageTwo,
    setCurrentPageTwo,
  };

  //change the cookie name when you want to clear local storage if any columns/filter values need to be reset
  useEffect(() => {
    const isCleared = localStorage.getItem("isCleared5");
    if (!isCleared) {
      clearLocalStorage();
      localStorage.setItem("isCleared5", true);
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const fetchFilters = async () => {
      dispatch(getFilters());
    };
    if (token) fetchFilters();
  }, [isAuthenticated]);

  return (
    <AppContext.Provider value={appContextValues}>
      <TableFilterContext.Provider value={tableFilterRefs}>
        {isAuthenticated && contextHolder}
        <Routing />
      </TableFilterContext.Provider>
    </AppContext.Provider>
  );
}

export default App;
