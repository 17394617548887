import Guidelines from "../pages/Guidelines";
import NPSDashboard from "../pages/NPSDashboard";
import SalesForce from "../pages/SalesForce";

export const routeArray = [
  { path: "/guidelines", component: <Guidelines /> },
  { path: "/", component: <NPSDashboard /> },
  { path: "/salesforce", component: <SalesForce /> },
  { path: "/*", component: <NPSDashboard /> },
];
